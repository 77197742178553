<template>
    <div class="page-box">
      <div class="header">
        <van-nav-bar
          :title="$t('mining.title')"
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div class="maincontent">

        <div class="title1">{{$t('mining.start-h1')}}</div>
        <div class="title2">{{$t('mining.start-h2')}}</div>
        <div class="card-box">
            <div class="card-title" style="margin-bottom: 1.94rem;">{{$t('mining.start-card1-t')}}</div>
            <div style="display: flex;margin-bottom: 1.91rem;">
                <div style="flex: 1;text-align: center;">
                    <div style="font-size: 0.81rem;margin-bottom: 1.03rem;color: #333333;">{{$t('mining.start-card1-c1')}}</div>
                    <div style="font-size: 0.69rem;color: #333848;">{{$t('mining.start-card1-c2')}}</div>
                </div>
                <div style="flex: 1;text-align: center;">
                    <div style="font-size: 0.81rem;margin-bottom: 1.03rem;color: #333333;">{{$t('mining.start-card1-c3')}}</div>
                    <div style="font-size: 0.69rem;color: #333848;">{{$t('mining.start-card1-c4')}}</div>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <div style="width: 4.81rem;">
                    <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/anquan.png')" />
                    <div style="font-size: 0.69rem;color: #333848;margin-top: 0.56rem;">{{$t('mining.start-card1-c5')}}</div>
                </div>
                <div style="width: 4.81rem;">
                    <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/qiandai.png')" />
                    <div style="font-size: 0.69rem;color: #333848;margin-top: 0.56rem;">{{$t('mining.start-card1-c6')}}</div>
                </div>
                <div style="width: 4.81rem;">
                    <van-image width="2.88rem" height="2.88rem" :src="require('../../assets/img/yingyong.png')" />
                    <div style="font-size: 0.69rem;color: #333848;margin-top: 0.56rem;">{{$t('mining.start-card1-c7')}}</div>
                </div>
            </div>
        </div>
        <div class="card-box">
            <div class="card-title" style="margin-bottom: 0.66rem;">{{$t('mining.start-card2-t')}}</div>
            <div class="card-des" style="margin-bottom: 0.31rem;">{{$t('mining.start-card2-c1')}}</div>
            <div class="card-des">{{$t('mining.start-card2-c2')}}</div>
        </div>
        <div class="card-box">
            <div class="card-title" style="margin-bottom: 0.66rem;">{{$t('mining.start-card3-t')}}</div>
            <div class="card-des">{{$t('mining.start-card3-c')}}</div>
        </div>   
      </div>
      <div class="bottom-box">
        <div class="btn" @click="$router.push('/miningProjectList')">{{$t('mining.title')}}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {}
    },
    created() {
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .page-box{
    min-height: 100vh;
    background: #3C74EB;
  }
  .maincontent {
    background: #3C74EB;
    padding: 80px 0.94rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
    .title1{
        font-size: 0.94rem;
        color: #FFFFFF;
        margin-bottom: 0.59rem;
    }
    .title2{
        font-size: .69rem;
        color: #FFFFFF;
        line-height: 1.06rem;
        margin-bottom: 1.09rem;
    }
    .card-box{
        padding: 1.38rem 0.88rem;
        width: 100%;
        background: #FFFFFF;
        border-radius: 0.38rem;
        margin: 0 auto;
        margin-bottom: 0.94rem;
    }
    .card-title{
        font-size: 1.19rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #325DE3;
    }
    .card-des{
        font-size: 0.75rem;
        color: #333333;
        line-height: 1.13rem;
    }
  }
  .bottom-box{
    position: fixed;
    bottom: 2.5rem;
    width: 100%;
    padding: 0 0.94rem;
    .btn{
        height: 2.5rem;
        line-height: 2.5rem;
        background: #325DE3;
        border-radius: 0.19rem;
        text-align: center;
        font-size: 0.94rem;
        color: #F5F5F5;
    }
  }
</style>
  